/*
 * File: Modal.tsx
 * Project: @inventures/react-lib
 * File Created: Wednesday, 23rd September 2020 4:10:47 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Wednesday, 12th July 2023 6:57:54 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2020 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Image from 'next/image';

const IconContainer = styled('div')({
  position: 'relative',
  height: '14px',
  width: '14px',
  marginLeft: '90%',
  marginTop: '20px',
});

const Container = styled('div')({});
const DialogTitle = styled(Typography)({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '17px',
  lineHeight: '150%',
  letterSpacing: '0.15px',
  color: '#414046',
  textAlign: 'center',
  marginBottom: '12px',
});

const DialogContentText = styled(Typography)({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  letterSpacing: '-0.011em',
  textAlign: 'center',
  color: '#414046',
  margin: '0px 18px 12px 18px',
});

const DialogActions = styled('div', {
  shouldForwardProp: (prop) => prop !== 'direction',
})<{ direction?: 'row' | 'column' }>(({ direction }) => ({
  display: 'flex',
  flexDirection: direction || 'column',
  alignItems: 'center',
  padding: direction === 'row' ? '16px' : '17px 0px',
  gap: direction === 'row' ? '16px' : '0px',
}));
const ButtonAction = styled(Button)({
  borderRadius: '100px',
  height: '42px',
  width: '199px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textDecoration: 'none',
  textTransform: 'none',
});
const Pointer = styled('div')({
  cursor: 'pointer',
});
export interface AlertModalContentProps {
  title: string;
  content?: string;
  children?: React.ReactElement;
  actions?: ActionType[];
  actionsDirection?: 'row' | 'column';
  AlignContentLeft?: boolean;
  changeDialogPaper?: boolean;
}
export interface AlertModalProps extends AlertModalContentProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  onBackdropClick?: () => void;
}

type ActionType = {
  text: string;
  onActionClick: () => void;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
};

interface ModalDialogContentProps {
  alignContentLeft?: boolean;
}
interface ModalDialogProps {
  changeDialogPaper: boolean;
}
const ModalDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'changeDialogPaper',
})<ModalDialogProps>(({ changeDialogPaper }) => ({
  '& .MuiDialog-paper': {
    margin: 16,
    maxWidth: 336,
    ...(changeDialogPaper
      ? {
          width: '100%',
          minHeight: 197,
        }
      : {}),
  },
}));
export const ModalDialogContentText = styled(DialogContentText, {
  shouldForwardProp: (prop) => prop !== 'alingContentLeft',
})<ModalDialogContentProps>(({ alignContentLeft }) => ({
  textAlign: alignContentLeft ? 'left' : 'center',
  whiteSpace: 'pre-line',
}));
export function AlertModal(props: AlertModalProps) {
  const {
    title,
    open,
    content,
    children,
    actions,
    actionsDirection,
    setOpen,
    onClose,
    disableEscapeKeyDown,
    onBackdropClick,
    AlignContentLeft,
    changeDialogPaper,
    ...otherProps
  } = props;
  const handleClose = () => {
    if (onClose) onClose();
    else if (actions.length === 1) actions[0].onActionClick();
    setOpen(false);
  };
  const haveHandleClose = actions?.length === 1 || onClose;

  return (
    <ModalDialog
      {...otherProps}
      open={open}
      onClose={handleClose}
      changeDialogPaper={changeDialogPaper}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onBackdropClick={onBackdropClick}
    >
      <Container>
        <IconContainer>
          {haveHandleClose && (
            <Pointer>
              <Image
                layout="fill"
                src="/assets/images/home/closeicon.webp"
                alt="closeIcon"
                objectFit="contain"
                onClick={handleClose}
              />
            </Pointer>
          )}
        </IconContainer>
        <DialogTitle>{title}</DialogTitle>
        {content && (
          <ModalDialogContentText alignContentLeft={AlignContentLeft}>
            {content}
          </ModalDialogContentText>
        )}
        {children}
        <DialogActions direction={actionsDirection}>
          {actions?.map((action: ActionType, index: number) => (
            <ButtonAction
              key={index}
              onClick={action.onActionClick}
              color="primary"
              variant={action.variant}
            >
              {action.text}
            </ButtonAction>
          ))}
        </DialogActions>
      </Container>
    </ModalDialog>
  );
}

interface ErrorModalProps {
  open: boolean;
  title: string;
  description: string;
  handleBack: () => void;
  whatsappText: string;
}

export function ErrorModal(props: ErrorModalProps) {
  const { open, title, description, handleBack, whatsappText } = props;
  return (
    <AlertModal
      title={title}
      open={open}
      setOpen={() => {}}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      actions={[
        {
          text: 'Aceptar',
          onActionClick: handleBack,
          variant: 'contained',
        },
      ]}
    >
      <ModalDialogContentText>
        {description}, intenta nuevamente o{' '}
        <Link
          target="_blank"
          href={`https://api.whatsapp.com/send?phone=56972622626&text=${encodeURI(
            whatsappText,
          )}`}
        >
          contáctanos para recibir ayuda
        </Link>
      </ModalDialogContentText>
    </AlertModal>
  );
}
